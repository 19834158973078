$breakpoint-tablet: 64em;

* {
  box-sizing: border-box;
  h1 {
    font-size: 2rem;
    font-family: 'Quicksand', Helvetica, sans-serif;
  }
  h2 {
    font-size: 1.8rem;
    font-family: 'Quicksand', Helvetica, sans-serif;
  }

  h3 {
    font-size: 1.4rem;
    font-family: 'Quicksand', Helvetica, sans-serif;
  }
}
html,
body {
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f6f5f1;
  font-family: 'Karla', Helvetica, sans-serif;
}

ul {
  list-style: none;
}

.navbar {
  // background: linear-gradient(rgba(93, 9, 111, 0.5), rgba(93, 9, 111, 0.5)),
  //   repeat url('./components/layout/images/triangular.png');
  background: #885e95;
  height: inherit;
  // border-bottom: 2px solid #000000;
  font-size: 1.2rem;

  a:link {
    text-decoration: none;
    color: #ffffff;
  }
  a:visited {
    color: #ffffff;
  }
  a {
    outline: none;
  }
  h1 {
    font-family: 'Dancing Script', 'Quicksand', Helvetica;
    color: #ffffff;
    margin: 10px 0;
  }
  @media (min-width: $breakpoint-tablet) {
    h1 {
      font-size: 2.5rem;
    }
  }

  li {
    display: inline;

    a:hover {
      color: #000;
    }
  }
  button {
    padding: 10px;
    font-size: 1.1rem;
    text-decoration: none;
    border: none;
    color: #ffffff;
    background-image: linear-gradient(to bottom, #faa51a 0%, #f47a20 100%);
  }
  button:hover {
    background-image: linear-gradient(to bottom, #f88e11 0%, #f06015 100%);
  }
  button:active {
    color: #000000;
  }
  .menu {
    display: none;

    @media (min-width: $breakpoint-tablet) {
      display: block;
    }
  }
}

.banner {
  background: no-repeat url('./components/layout/images/seattle-banner.jpg');
  width: 100%;
  min-height: 350px;
  background-size: cover;
  margin-right: 0;
  margin-left: 0;

  @media (min-width: $breakpoint-tablet) {
    padding: 35px 0 0 0;
  }

  h1 {
    max-width: 600px;
    font-weight: 900;
    color: #ffffff;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
      1px 1px 0 #000;
    background: rgba(0, 0, 0, 0.6);
    padding: 15px 0 15px 0;
  }
  .city {
    font-size: 1.4rem;
    max-width: 300px;
  }
}

.container {
  max-width: 900px;
}

.services {
  margin: 0 0 20px 0;
  // h3 {
  //   font-size: 1.4rem;
  // }
  ul {
    padding: 15px 0 0 0;
    border-style: solid;
    border-width: 2px 0 2px 0;
    border-color: #8a6096;

    li {
      padding: 0 0 15px 0;
      font-size: 1.1rem;
    }
  }
}
.packages {
  margin: 0 15px;

  h3 {
    margin: 0 0 10px 0;
  }

  .package-grid {
    display: none;
  }

  .center {
    text-align: center;
    justify-content: center;
  }
  .package-list {
    span {
      font-size: 0.8rem;
    }
    ul {
      margin: 0;
      padding: 0 10px;
    }
    ul:nth-child(3) {
      padding: 5px 0;
      margin: 0 10px 5px 10px;
      border-top: 2px solid #f6f5f1;

      li:last-child {
        padding: 0;
      }
    }
    li {
      padding: 0 0 10px 0;
    }
    h3 {
      background: #885e95;
      padding: 5px 10px;
      color: #f6f5f1;
    }
    .group-one,
    .group-two {
      background: #e5e3dc;
    }

    .group-one {
      margin: 0 0 20px 0;
    }
  }
  .addon-grid {
    div:nth-child(2),
    div:nth-child(3) {
      background-color: #e5e3dc;
    }
    div:nth-child(2) {
      margin: 0 0 20px 0;
    }
    .highlight {
      background: #885e95;
      padding: 5px 10px;
      margin: 0px;
      color: #f6f5f1;
    }
    ul {
      padding: 0 10px;
      margin: 10px 0;
    }
    li {
      padding: 0 0 10px 0;
      span {
        font-size: 0.8rem;
      }
    }

    .addon-note {
      text-align: left;
      font-size: 0.8rem;
    }
  }

  @media (min-width: $breakpoint-tablet) {
    .package-list {
      display: none;
    }
    .false {
      background-color: #e5e3dc;
    }
    .package-grid {
      display: grid;
      grid-template-rows: repeat(20, 50px);
      grid-template-columns: 3fr repeat(5, 1fr);
      grid-gap: 3px;

      .title {
        font-size: 1.2rem;
      }

      .vert-align {
        // line-height: 50px;
        display: flex;
        align-items: center;
      }

      span {
        font-size: small;
        font-style: italic;
      }
      .false {
        background-color: #e5e3dc;
      }

      .true {
        background-color: #885e95;
        text-align: center;
        color: #f6f5f1;

        span {
          font-size: 2rem;
        }
      }
    }

    .addon-grid {
      display: grid;
      grid-template-rows: 2fr repeat(2);
      grid-template-columns: repeat(2, 1fr);
      text-align: center;
      grid-gap: 0px 20px;

      .title {
        grid-column-start: 1;
        grid-column-end: 3;
      }

      div:nth-child(2),
      div:nth-child(3) {
        background-color: #e5e3dc;
        margin: 0;
      }
      ul {
        padding: 0;
      }
      li {
        padding: 5px;

        span {
          font-size: 0.8rem;
        }
      }

      .addon-note {
        text-align: left;
        font-size: 0.8rem;
      }
    }
  }
}

.reviews {
  background-color: #885e95;
  color: #fff;
  max-height: 240px;
  padding: 5px 0 0 0;
  overflow: hidden;

  p:nth-child(2) {
    font-style: italic;
    font-size: 0.9rem;
  }

  @media (min-width: 450px) {
    margin: 0 auto;
    max-height: 210px;
    max-width: 700px;
  }

  @media (min-width: 500px) {
    max-height: 180px;
  }

  @media (min-width: 600px) {
    max-height: 160px;
  }

  @media (min-width: 800px) {
    max-height: 150px;
  }
}
.testimony {
  background-color: #e5e3dc;
  max-width: 500px;
  margin: 0 auto 20px auto;
  line-height: 1.3rem;

  p {
    margin: 0;
    padding: 5px 10px;
  }
  .client {
    background-color: #885e95;
    color: #ffffff;
  }
}
.clients {
  .center {
    text-align: center;
  }
}
.about-us {
  margin: 0 0 20px 0;
  h2 {
    width: 180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
    border-style: solid;
    border-width: 0 0 3px 0;
    border-color: #8a6096;
  }

  img {
    width: 90%;
    max-width: 300px;
    margin: 30px 0 30px 0;
    border: 2px solid white;
    box-shadow: 0px 0px 8px 2px #000000;
  }
  span {
    font-family: 'Dancing Script', 'Quicksand', Helvetica;
    font-size: 2.3rem;
    margin: 0 20px 0 0;
  }
}

.contact {
  padding: 0 0 20px 0;

  h2 {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 5px;
    border-style: solid;
    border-width: 0 0 3px 0;
    border-color: #8a6096;
  }

  h4 {
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  img {
    margin: 10px 10px 0 0;
    padding: 0;
    height: 75px;

    @media (min-width: $breakpoint-tablet) {
      height: 150px;
      margin: 30px 10px 0 0;
    }
  }

  form {
    label {
      margin-bottom: 13px;
    }
    input {
      padding: 0;
      font-family: 'Quicksand', Helvetica, sans-serif;
    }

    textarea {
      padding: 5px;
      resize: none;
      min-height: 75px;
      font-family: 'Quicksand', Helvetica, sans-serif;
    }
  }

  ul {
    padding: 10px 0 5px 0;
  }
}

.footer {
  background-color: #000;
  color: #9f9f9f;
  font-size: 1rem;
  padding: 10px;
  span {
    margin: 5px 0 5px 0;
  }
}

// Sidebar

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 32px;
  height: 30px;
  right: 20px;
  border: solid 4px;
  border-color: #8a6096;
  top: 15px;
  background-color: #8a6096;

  @media (min-width: $breakpoint-tablet) {
    display: none;
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #ffffff;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #ffffff;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 30px;
  width: 30px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  top: 0px;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background-color: #8a6096;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;

  a:link {
    text-decoration: none;
    color: #ffffff;
  }
  a:visited {
    color: #ffffff;
  }
  a {
    outline: none;
  }
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 10px;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
